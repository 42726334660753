<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="data"
      :scroll="{ x: 1800 }"
      :pagination="pagination"
      :loading="loading"
    >
      <template slot="chaozuo" slot-scope="text, record">
        <div>
          <a-popover placement="left">
            <template slot="content">
              <a-button type="primary">警报确认</a-button>
              <a-button type="primary">手动恢复</a-button>
              <a-button type="primary">应对</a-button>
              <a-button type="primary">顺位恢复</a-button>
              <a-button type="primary">警报输出</a-button>
              <a-button type="primary" @click="() => remarks(record)"
                >添加注释</a-button
              >
            </template>
            <a>选择操作</a>
          </a-popover>
        </div>
      </template>
    </a-table>
    <a-modal
      title="注释"
      centered
      :visible="visible"
      :closable="false"
      :ok-button-props="confirm_forbidden"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-textarea
        v-model="value"
        placeholder="请输入内容......"
        :auto-size="{ minRows: 10, maxRows: 10 }"
      />
    </a-modal>
  </div>
</template>

<script>
import { remarks } from "../../../api/api";

export default {
  name: "alarmtable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      value: "",
      params: "",
      confirm_forbidden: { props: { disabled: true } },
      confirmLoading: false,
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共 ${total} 条数据`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 65,
          ellipsis: true,
          scopedSlots: { customRender: "NO" },
          fixed: "left",
        },
        {
          title: "发生日期",
          dataIndex: "DATE_TIME",
          width: 170,
          ellipsis: true,
        },
        {
          title: "用户ID",
          dataIndex: "NOTIFICATION_MODE",
          width: 100,
          ellipsis: true,
        },
        {
          title: "服务",
          dataIndex: "FLC_ALARM_HISTORY_TYPE",
          width: 140,
          ellipsis: true,
        },
        {
          title: "画面名称",
          dataIndex: "SITE_NAME",
          width: 140,
          ellipsis: true,
        },
        {
          title: "操作内容（概要）",
          dataIndex: "EQUIPMENT_NAME1",
          width: 140,
          ellipsis: true,
        },
        {
          title: "操作结果",
          dataIndex: "EQUIPMENT_NAME2",
          width: 190,
          ellipsis: true,
        },       
        {
          title: "注释",
          dataIndex: "DESCRIPTION",
          width: 140,
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== "") {
          this.confirm_forbidden.props.disabled = false;
        } else {
          this.confirm_forbidden.props.disabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    remarks(key) {
      this.visible = true;
      this.params = key.PARAMS;
      console.log(key);
    },
    handleOk(e) {
      this.confirmLoading = true;
      let data = new FormData();
      data.append("params", this.params);
      data.append("comment", this.value);
      remarks(data)
        .then((res) => {
          this.confirmLoading = false;
          this.visible = false;
          this.$parent.onSubmit();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
button {
  margin-right: 10px;
}
</style>