<!--门禁模块--操作·控制记录页-->
<template>
  <div id="cardrecords">
    <div class="header">
      <my-headertitle>用户操作记录</my-headertitle>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item label="用户名称">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item label="初始日期/时间选择" prop="date1">
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @openChange="handleStartOpenChange"
                @change="handleCalendarChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item label="终止日期/时间选择" prop="date2">
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">查询</a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>
      <div class="main">
        <my-tabletitle>记录列表</my-tabletitle>
        <my-table :data="data" :loading="loading"></my-table>
      </div>
    </div>
    <div class="foor"></div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import Table from "./components/userRecordTable";
import { userRecordData } from "../../api/device";
import moment from "moment";

export default {
  data() {
    return {
      endOpen: false,
      form: {
        name: "",
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      data: undefined,
      loading: false,
      startDate: undefined,
      rules: {
        date1: [
          { required: true, message: "请选择日期或时间", trigger: "change" },
        ],
        date2: [
          { required: true, message: " 请选择日期或时间", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    moment,
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      this.startDate = moment(value._d).format("YYYY-MM-DD HH:mm:ss");
      this.form.date2 = null;
    },
    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            startDate: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDate: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
            personName: this.form.name,
          };
          userRecordData(data)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("data", res);
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.data = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 初始日期/时间的日期不可选范围
    disabledStartDate(startValue) {
      const endValue = this.form.date2;
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      let startDate = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() >= endValue.valueOf() ||
        endValue >= moment().endOf() ||
        endValue >= moment(new Date(startDate)).add(1, "months")
      );
    },
    // 初始日期/时间选择弹出日历和关闭日历的回调
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    // 终止日期/时间选择弹出日历和关闭日历的回调
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-table": Table,
  },
};
</script>

<style scoped>
#cardrecords {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  height: 100%;
  padding: 0 20px;
  overflow: auto;
}
.main {
  width: 100%;
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
button {
  width: 120px;
}
</style>